import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "services/api/user";
import jwtDecode from "utils/jwt-decode";

export const signIn = createAsyncThunk("user/signIn", async (params) => {
  await fakePromise();

  const { success, data, error } = await API.signIn(params);

  if (!success) {
    return { success, error };
  }

  const token = jwtDecode(data.token);

  return {
    success: true,
    data: {
      ...token,
      menu: data.menu,
    },
  };
});

const fakePromise = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 2000);
  });
};
