import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "components/screen-container/components/drawer/styles";

const SearchBar = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box sx={styles.searchBarContainer}>
      <InputBase
        sx={styles.searchTextInput}
        placeholder="Buscar menú"
        inputProps={{ "aria-label": "search google maps" }}
      />
      <IconButton type="submit" sx={styles.searchIcon} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Box>
  );
};

export default SearchBar;
