import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  activeCollapsableMenuId: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setActiveCollapsableMenuId: (state, { payload }) => {
      state.activeCollapsableMenuId = payload;
    },
  },
  extraReducers: {},
});

// actions
const { setIsLoading, setActiveCollapsableMenuId } = appSlice.actions;

// export actions
export { setIsLoading, setActiveCollapsableMenuId };

// export reducer
export default appSlice.reducer;
