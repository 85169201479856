import React from "react";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "screens/general-statistics/styles";
import ScreenContainer from "components/screen-container";

const Favorites = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return <ScreenContainer title={"Favoritos"}></ScreenContainer>;
};

export default Favorites;
