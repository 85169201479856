import React from "react";
import { useTheme } from "@mui/material/styles";
import MUIDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import SearchBar from "components/screen-container/components/drawer/components/search-bar";
import Menu from "components/screen-container/components/drawer/components/menu";
import { useStyles } from "components/screen-container/components/drawer/styles";

const Drawer = ({ isDrawerOpen }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <MUIDrawer sx={styles.drawer} variant="persistent" anchor="left" open={isDrawerOpen}>
      <Box sx={styles.searchBarFirstContainer}>
        <Box sx={styles.searchBarSecondContainer}>
          <SearchBar />
        </Box>
      </Box>
      <Box sx={styles.menuGroupContainer}>
        <Menu />
      </Box>
    </MUIDrawer>
  );
};

export default Drawer;
