import React from "react";
import { useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@mui/material/Typography";
import { useStyles } from "components/screen-container/components/drawer/styles";

const MenuCollapseItem = ({ nodes, isCollapseOpen, onClick, currentPath }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
      <List disablePadding>
        {nodes.map(({ id, name, route }) => (
          <ListItem
            button
            sx={{
              ...styles.menuItemDetailContainer,
              ...(route === currentPath && styles.selectedItemDetail),
            }}
            key={name}
            onClick={() => onClick({ id, route })}
          >
            <ListItemIcon sx={styles.menuItemDetailIconContainer}>
              <CircleIcon sx={styles.menuItemDetailIcon} />
            </ListItemIcon>
            <ListItemText primary={<Typography sx={styles.menuItemText}>{name}</Typography>} />
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
};

export default MenuCollapseItem;
