import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signIn } from "redux/user/thunks";
import { validateLogin } from "screens/login/helpers/validate-login";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import { useStyles } from "screens/login/components/form/styles";
import { LOGO_BLACK } from "styles/assets";

const Login = () => {
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { requesting, error } = useSelector((state) => state.user);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (requesting) {
      return;
    }

    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    const params = { email, password };

    const isValid = validateLogin(params, setEmailError, setPasswordError);

    if (!isValid) {
      return;
    }

    const response = await dispatch(signIn(params)).unwrap();

    if (response.success) {
      navigate("/dashboard/general");
    }
  };

  return (
    <Box sx={styles.box}>
      <Box sx={styles.logo}>
        <img width="230" src={LOGO_BLACK} />
      </Box>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={styles.form}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="email"
          label="Correo electrónico"
          type="email"
          id="email"
          autoComplete="email"
          autoFocus
          error={emailError.length > 0}
          helperText={emailError}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
          error={passwordError.length > 0}
          helperText={passwordError}
        />
        <FormControlLabel
          control={<Checkbox value="remember" />}
          label="Mantener sesión iniciada"
          sx={styles.rememberMeLabel}
        />
        {error && (
          <FormHelperText error sx={styles.errorMessage}>
            *Correo electrónico o contraseña incorrectas
          </FormHelperText>
        )}
        <Button type="submit" fullWidth variant="contained" sx={styles.loginBtn}>
          {requesting ? <CircularProgress sx={styles.circularProgress} size={25} /> : "Acceder"}
        </Button>
        <Grid container justifyContent="center">
          <Grid item justifyContent="center">
            <Link to="/forgot" style={styles.forgotPasswordLink}>
              ¿Olvidaste tu contraseña?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Login;
