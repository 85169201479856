const EMPTY_ERROR = "";

export const validateLogin = ({ email, password }, setEmailError, setPasswordError) => {
  const emailError = getEmailError(email);
  const passwordError = getPasswordError(password);

  setEmailError(emailError);
  setPasswordError(passwordError);

  const isValid = emailError.length === 0 && passwordError.length === 0;

  return isValid;
}

const getEmailError = (email) => {
  if (email.trim().length === 0) {
    return "Correo electrónico es requerido";
  }

  if (!isValidEmail(email)) {
    return "Correo electrónico invalido";
  }

  return EMPTY_ERROR;
}

const isValidEmail = (email) => {
  const regExp = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
  return String(email).search(regExp) != -1;
}

const getPasswordError = (password) => {
  if (password.trim().length === 0) {
    return "Contraseña es requerida";
  }

  return EMPTY_ERROR;
}