import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "services/api/activity";

export const getActivities = createAsyncThunk("activity/getActivities", async (params) => {
  await fakePromise();
  const { success, data, error } = await API.getActivities(params);
  if (!success) {
    return { success, error };
  }
  return {
    success: true,
    data,
  };
});

export const getStadistics = createAsyncThunk("activity/getStadistics", async (params) => {
  await fakePromise();
  const { success, data, error } = await API.getStadistics(params);
  if (!success) {
    return { success, error };
  }
  return {
    success: true,
    data,
  };
});

const fakePromise = () => {
  return new Promise((resolve) => {
    // setTimeout(() => {
    resolve();
    // }, 2000);
  });
};
