import { DRAWER_WIDTH, HEADER_HEIGHT } from "styles/constants";

export const useStyles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: HEADER_HEIGHT,
    background: theme.palette.darkGray.main,
    "& .MuiToolbar-regular": {
      paddingLeft: "16px",
    },
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "244px",
  },
  toggleDrawerMenu: {
    marginRight: "16px",
  },
  profileMenu: {
    marginTop: "40px",
    borderRadius: "8px",
    "& .MuiPaper-root": {
      width: "228px",
      borderRadius: "8px",
      border: "1px solid",
      borderColor: theme.palette.lightGray.main,
    },
  },
  avatar: {
    color: theme.palette.darkGray.main,
    background: theme.palette.white.main,
    fontSize: "16px",
    width: "35px",
    height: "35px",
    fontWeight: "700",
    marginRight: "8px",
    marginLeft: "8px",
    "&:hover": {
      background: theme.palette.lightGray.main,
      transition: "all 0.2s ease-in-out 0s",
    },
    lineHeight: "35px",
  },
  profileMenuInfoMainContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "0px",
    padding: "6px 16px",
  },
  profileMenuInfoAvatar: {
    color: theme.palette.white.main,
    background: theme.palette.darkGray.main,
    width: "45px",
    height: "45px",
    fontWeight: "700",
    marginRight: "8px",
  },
  profileMenuInfoSecundaryContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  profileMenuInfoUserName: {
    color: theme.palette.black.main,
    fontWeight: "500",
    lineHeight: "15px",
  },
  profileMenuInfoUserRole: {
    color: theme.palette.black.main,
    fontWeight: "500",
    fontSize: "12px",
    marginTop: "5px",
  },
  profileMenuItemText: {
    color: theme.palette.black.main,
    fontSize: "0.98rem",
  },
  divider: {
    borderColor: theme.palette.lightGray.main,
    margin: "6px 16px",
  },
});
