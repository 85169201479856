import axios from "axios";

export const API = {
  getActivities: async (params) => {
    try {
      const path = "api/getActivities";

      const payload = {};

      const response = {
        success: true,
        code: 200,
        message: "Success.",
        data: [
          {
            id: 1,
            initials: "RU",
            date: "Hace 1 semana",
            propertyInterested: "Propiedad 1",
            name: "Rodolfo Urrea",
            phone: "+52 123 456 7890",
            email: "rodolfo@email.com",
            clientType: "Agente",
            message: "Hola, estoy interesado en [Propiedad 1]",
            propertyLink: "http://www.google.com",
          },
          {
            id: 2,
            initials: "VU",
            date: "Hace 2 semanas",
            propertyInterested: "Propiedad 2",
            name: "Victor Urrea",
            phone: "+52 123 456 7890",
            email: "victor@email.com",
            clientType: "Cliente",
            message: "Hola, estoy interesado en [Propiedad 2]",
            propertyLink: "http://www.google.com",
          },
          {
            id: 3,
            initials: "AC",
            date: "Hace 1 semana",
            propertyInterested: "Propiedad 1",
            name: "Angelina Corona",
            phone: "+52 123 456 7890",
            email: "angelina@email.com",
            clientType: "Agente",
            message: "Hola, estoy interesado en [Propiedad 1]",
            propertyLink: "http://www.google.com",
          },
          {
            id: 4,
            initials: "JO",
            date: "Hace 2 semanas",
            propertyInterested: "Propiedad 2",
            name: "Jesús Osuna",
            phone: "+52 123 456 7890",
            email: "jesus@email.com",
            clientType: "Cliente",
            message: "Hola, estoy interesado en [Propiedad 2]",
            propertyLink: "http://www.google.com",
          },
        ],
        timestamp: 1693980113237,
      };

      return { success: true, data: response.data };
    } catch (err) {
      return { sucess: false, error: err };
    }
  },
  getStadistics: async (params) => {
    try {
      const path = "api/getStadistics";

      const payload = {};

      const response = {
        success: true,
        code: 200,
        message: "Success.",
        data: {
          deals_count: {
            active: 100,
            won: 220,
            lost: 50,
            negotiation: 75,
            waiting: 30,
          },
          leads_count: {
            lastday: 400,
            lasttwo: 220,
            lastweek: 700,
            last2week: 1500,
            lastmonth: 3100,
            last2month: 2200,
          },
        },
        timestamp: 1693980113237,
      };

      return { success: true, data: response.data };
    } catch (err) {
      return { sucess: false, error: err };
    }
  },
};
