import axios from "axios";

export const API = {
  signIn: async (params) => {
    try {
      const path = "api/signIn";

      const payload = {
        email: params.email,
        password: params.password,
      };

      const response = {
        success: true,
        code: 200,
        message: "Success.",
        data: {
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxLCJmaXJzdE5hbWUiOiJSb2RvbGZvIiwibGFzdE5hbWUiOiJVcnJlYSIsImVtYWlsIjoicm9kb0BnbWFpbC5jb20ifSwiaWF0IjoxNjkzOTgwMTEzLCJleHAiOjE2OTc1ODAxMTN9.2F0gqp0W0Mzea4jOlK80GRpIKEqcrEKtTsxAfd5xLh0",
          menu: [
            {
              group: "Dashboard",
              children: [
                {
                  id: 1,
                  name: "Resumen",
                  icon: "Dashboard",
                  route: "/dashboard/general",
                  children: null,
                },
                {
                  id: 2,
                  name: "Favoritos",
                  icon: "FavoriteBorder",
                  route: "/dashboard/favorites",
                  children: null,
                },
                {
                  id: 3,
                  name: "Buzón",
                  icon: "Inbox",
                  route: "/dashboard/inbox",
                  children: null,
                },
              ],
            },
            {
              group: "CRM",
              children: [
                {
                  id: 4,
                  name: "Contactos",
                  icon: "Group",
                  route: "/crm/leads",
                  children: null,
                },
                {
                  id: 5,
                  name: "Tareas",
                  icon: "Assignment",
                  route: "/crm/tasks",
                  children: null,
                },
                {
                  id: 6,
                  name: "Mis Propiedades",
                  icon: "MapsHomeWork",
                  route: "/crm/properties",
                  children: null,
                },
                {
                  id: 7,
                  name: "Generar Contratos",
                  icon: "Description",
                  children: [
                    {
                      id: 8,
                      name: "Compraventa",
                      route: "/crm/contracts/sale",
                    },
                    {
                      id: 9,
                      name: "Arrendamiento",
                      route: "/crm/contracts/lease",
                    },
                    {
                      id: 10,
                      name: "Apartado",
                      route: "/crm/contracts/booking",
                    },
                    {
                      id: 11,
                      name: "Comisión",
                      route: "/crm/contracts/commision",
                    },
                  ],
                },
              ],
            },
            {
              group: "Estadísticas",
              children: [
                {
                  id: 12,
                  name: "Reporte de Clientes",
                  icon: "ContactPage",
                  children: [
                    {
                      id: 13,
                      name: "Listado de Clientes",
                      route: "/statistics/clientList",
                    },
                    {
                      id: 14,
                      name: "Clientes Potenciales",
                      route: "/statistics/potentialClients",
                    },
                    {
                      id: 15,
                      name: "Clientes Asignados",
                      route: "/statistics/assignedClients",
                    },
                    {
                      id: 16,
                      name: "Clientes Cerrados",
                      route: "/statistics/closedClients",
                    },
                    {
                      id: 17,
                      name: "Fuentes de Clientes",
                      route: "/statistics/clientSources",
                    },
                  ],
                },
                {
                  id: 18,
                  name: "Reporte de Visitas",
                  icon: "Assessment",
                  children: [
                    {
                      id: 19,
                      name: "Visitas Generales",
                      route: "/statistics/generalVisits",
                    },
                    {
                      id: 20,
                      name: "Visitas por Propiedad",
                      route: "/statistics/propertyVisits",
                    },
                    {
                      id: 21,
                      name: "Fuentes de Solicitudes",
                      route: "/statistics/requestSources",
                    },
                  ],
                },
              ],
            },
            {
              group: "Otros",
              children: [
                {
                  id: 22,
                  name: "Seguridad",
                  icon: "Security",
                  children: [
                    {
                      id: 23,
                      name: "Roles",
                      route: "/security/roles",
                    },
                    {
                      id: 24,
                      name: "Accesos",
                      route: "/security/accesses",
                    },
                    {
                      id: 25,
                      name: "Agentes",
                      route: "/security/agents",
                    },
                    {
                      id: 26,
                      name: "Agencias",
                      route: "/security/agencies",
                    },
                    {
                      id: 27,
                      name: "Propietarios",
                      route: "/security/owners",
                    },
                  ],
                },
                {
                  id: 28,
                  name: "Ajustes",
                  icon: "Settings",
                  children: [
                    {
                      id: 29,
                      name: "Tipos de Pago",
                      route: "/settings/paymentTypes",
                    },
                    {
                      id: 30,
                      name: "Monedas",
                      route: "/settings/currencies",
                    },
                    {
                      id: 31,
                      name: "Caja / Banco",
                      route: "/settings/bank",
                    },
                    {
                      id: 32,
                      name: "Mi Perfil",
                      route: "/settings/myProfile",
                    },
                  ],
                },
              ],
            },
          ],
        },
        timestamp: 1693980113237,
      };

      return { success: true, data: response.data };
    } catch (err) {
      return { sucess: false, error: err };
    }
  },
};
