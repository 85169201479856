import React from "react";
import { useTheme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { useStyles } from "components/screen-container/components/drawer/styles";
import useMuiIcon from "components/screen-container/components/drawer/hooks/use-mui-icon";

const MenuOption = ({ title, icon, onClick, isCollapsable, selected, disabled }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const Icon = useMuiIcon(icon);

  return (
    <ListItem
      selected={selected}
      button
      sx={styles.menuItemContainer}
      key={title}
      onClick={onClick}
      disabled={disabled}
    >
      <ListItemIcon sx={styles.menuItemIconContainer}>{Icon && <Icon sx={styles.menuItemIcon} />}</ListItemIcon>
      <ListItemText
        primary={
          <Typography sx={{ ...styles.menuItemText, ...(selected && styles.selectedMenuItemText) }}>{title}</Typography>
        }
      />
      {isCollapsable &&
        (selected ? <ExpandLessIcon sx={styles.arrowIcon} /> : <ExpandMoreIcon sx={styles.arrowIcon} />)}
    </ListItem>
  );
};

export default MenuOption;
