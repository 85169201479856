import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setActiveCollapsableMenuId } from "redux/app/slice";
import useInitialMenuOption from "components/screen-container/components/drawer/hooks/use-initial-menu-option";
import MenuGroup from "components/screen-container/components/drawer/components/menu-group";
import MenuOption from "components/screen-container/components/drawer/components/menu-option";
import MenuCollapseItem from "components/screen-container/components/drawer/components/menu-collapse-item";

const Menu = () => {
  const { menu } = useSelector((state) => state.user);
  const { activeCollapsableMenuId } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const initialMenuOption = useInitialMenuOption(currentPath);

  const onPressMenuOption = ({ route }) => {
    navigate(route);
  };

  useEffect(() => {
    if (!activeCollapsableMenuId && initialMenuOption) {
      dispatch(setActiveCollapsableMenuId(initialMenuOption.id));
    }
  }, []);

  const onPressCollapsableMenuOption = (id) => {
    const isSelected = activeCollapsableMenuId === id;

    if (isSelected) {
      dispatch(setActiveCollapsableMenuId(null));
      return;
    }

    dispatch(setActiveCollapsableMenuId(id));
  };

  return menu.map(({ group, children }, index) => (
    <MenuGroup key={group} title={group} isLastGroup={menu.length - 1 === index}>
      {children.map((option, childIndex) =>
        !Array.isArray(option.children) ? (
          <MenuOption
            title={option.name}
            icon={option.icon}
            selected={option.route === currentPath}
            onClick={() => onPressMenuOption(option)}
            key={option.name + childIndex}
          />
        ) : (
          <React.Fragment key={option.name}>
            <MenuOption
              title={option.name}
              icon={option.icon}
              selected={activeCollapsableMenuId === option.id}
              onClick={() => onPressCollapsableMenuOption(option.id)}
              isCollapsable
            />
            <MenuCollapseItem
              nodes={option.children}
              isCollapseOpen={activeCollapsableMenuId === option.id}
              currentPath={currentPath}
              onClick={onPressMenuOption}
            />
          </React.Fragment>
        )
      )}
    </MenuGroup>
  ));
};

export default Menu;
