import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getActivities, getStadistics } from "redux/activity/thunks";
import { useTheme } from "@mui/material/styles";
import { Grid, Paper } from "@mui/material";
import { useStyles } from "screens/general-statistics/styles";
import ScreenContainer from "components/screen-container";
import LoadingIndicator from "components/loading-indicator";
import ActivitiesList from "screens/general-statistics/components/activities-list";
import StadisticsLeads from "screens/general-statistics/components/stadistics-leads";
import StadisticsDeals from "screens/general-statistics/components/stadistics-deals";

const GeneralStadistics = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const dispatch = useDispatch();
  const { requestingActivities, requestingStadistics } = useSelector((state) => state.activity);
  const isLoading = requestingActivities || requestingStadistics;

  useEffect(() => {
    dispatch(getActivities());
    dispatch(getStadistics());
  }, [dispatch]);

  return (
    <ScreenContainer title={"Resumen"}>
      <Grid container columnSpacing={3}>
        <Grid item xs={7}>
          <Paper sx={styles.activitiesContainer}>
            <ActivitiesList />
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <Paper sx={styles.stadisticsContainer}>
                <StadisticsLeads />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={styles.stadisticsContainer}>
                <StadisticsDeals />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isLoading && <LoadingIndicator />}
    </ScreenContainer>
  );
};

export default GeneralStadistics;
