import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { signOut } from "redux/user/slice";
import IconRoundedButton from "components/icon-rounded-button";
import { useStyles } from "components/screen-container/components/header/styles";
import { LOGO_WHITE } from "styles/assets";

const Header = ({ isDrawerOpen, toggleDrawer, showDrawerButton }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const isProfileMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch(signOut());
    handleProfileMenuClose();
    navigate("/login");
  };

  const avatarText = "RU";
  const userName = "Rodolfo Urrea";
  const userRole = "Admin";

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={"profile-menu"}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
      sx={styles.profileMenu}
      elevation={1}
    >
      <Box sx={styles.profileMenuInfoMainContainer}>
        <Avatar sx={styles.profileMenuInfoAvatar}>{avatarText}</Avatar>
        <Box sx={styles.profileMenuInfoSecundaryContainer}>
          <Typography component="p" sx={styles.profileMenuInfoUserName}>
            {userName}
          </Typography>
          <Typography component="p" sx={styles.profileMenuInfoUserRole}>
            {userRole}
          </Typography>
        </Box>
      </Box>
      <Divider sx={styles.divider} />
      <MenuItem sx={styles.profileMenuItemText} onClick={null}>
        Mi Perfil
      </MenuItem>
      <MenuItem sx={styles.profileMenuItemText} onClick={null}>
        Empresa
      </MenuItem>
      <Divider sx={styles.divider} />
      <MenuItem sx={styles.profileMenuItemText} onClick={handleSignOut}>
        Cerrar Sesión
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={styles.grow}>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          ...styles.appBar,
          ...(isDrawerOpen && styles.appBarShift),
        }}
      >
        <Toolbar>
          <Box sx={styles.logoContainer}>
            <img width="150" src={LOGO_WHITE} />
            {showDrawerButton && (
              <IconRoundedButton onClick={toggleDrawer} style={styles.toggleDrawerMenu}>
                <MenuIcon />
              </IconRoundedButton>
            )}
          </Box>
          <Box style={styles.grow} />
          <Box>
            <IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit">
              <Avatar sx={styles.avatar}>{avatarText}</Avatar>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderProfileMenu}
    </Box>
  );
};

export default Header;
