import React from "react";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "./styles";
import ScreenContainer from "components/screen-container";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Box, IconButton, Tooltip, Typography, Link } from "@mui/material";
import { Visibility, Edit, Delete } from "@mui/icons-material";

const rows = [
  {
    id: 1,
    avatarInitials: "JC",
    name: "Juan Carlos",
    email: "juan@gmail.com",
    phone: "1234567890",
    phoneType: "Movil",
    type: "Asesor",
    date: new Date(),
  },
  {
    id: 2,
    avatarInitials: "MP",
    name: "Maria Pérez",
    email: "maria@gmail.com",
    phone: "0987654321",
    phoneType: "Casa",
    type: "Inmobiliaria",
    date: new Date(),
  },
  {
    id: 3,
    avatarInitials: "AE",
    name: "Ana Espinoza",
    email: "ana@gmail.com",
    phone: "0987651234",
    phoneType: "Oficina",
    type: "Tradicional",
    date: new Date(),
  },
  // ... puedes agregar más datos aquí
];

const Leads = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const columns = [
    { field: "id", hide: true },
    {
      field: "name",
      headerName: "Nombre",
      flex: 0.2,
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <Avatar sx={styles.avatar}>{params.row.avatarInitials}</Avatar>
          <Box ml={2}>
            <Link href="https://www.google.com" color="gold.main" underline="none">
              <Typography variant="body2" sx={styles.name}>
                {params.value}
              </Typography>
            </Link>
          </Box>
        </Box>
      ),
    },
    {
      field: "contact",
      headerName: "Contacto",
      flex: 0.25,
      renderCell: (params) => (
        <Box>
          {params.row.email}
          <br />
          {params.row.phone}{" "}
          <Box component="span" ml={1} color="text.secondary">
            {params.row.phoneType}
          </Box>
        </Box>
      ),
    },
    { field: "type", headerName: "Tipo", flex: 0.15 },
    {
      field: "date",
      headerName: "Fecha",
      flex: 0.2,
      renderCell: (params) => (
        <Box>
          {params.value.toLocaleDateString()}
          <br />
          {params.value.toLocaleTimeString()}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Acción",
      flex: 0.2,
      renderCell: () => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="Ver">
            <IconButton sx={styles.iconButton}>
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton sx={styles.iconButton}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar">
            <IconButton sx={styles.iconButton}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <ScreenContainer title={"Clientes"}>
      <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection disableSelectionOnClick />
    </ScreenContainer>
  );
};

export default Leads;
