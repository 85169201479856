import React from "react";
import styled, { keyframes } from "styled-components";

const Container = ({ children }) => {
  const gradient = keyframes`0%{background-position:0% 50%} 50%{background-position:100% 50%} 100%{background-position:0% 50%};`;

  const Wrapper = styled.main`
    display: flex;
    height: 100vh;
    min-width: 100vw;
    justify-content: center;
    align-items: center;
    background-size: 300% 300%;
    background-image: linear-gradient(
      -45deg,
      #616161 0%,
      #505050 25%,
      #3a3a3a 50%,
      #222222 100%
    );
    animation: ${gradient} 20s ease infinite;
  `;

  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

export default Container;
