import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  IconButton,
  Typography,
  Divider,
  ListItemAvatar,
  ListItem,
  ListItemText,
  List,
  Pagination,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "screens/general-statistics/components/activities-list/styles";

const ActivitiesList = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const { activities } = useSelector((state) => state.activity);

  //Pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 3;
  const numberOfPages = Math.ceil(activities.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const displayedClients = activities.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <>
      <List sx={styles.main}>
        {displayedClients.map((client, index) => (
          <React.Fragment key={client.id}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar sx={styles.avatar}>{client.initials}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Typography variant="body2" sx={styles.date}>
                      {client.date}
                    </Typography>
                    <Typography variant="body2" sx={styles.title}>
                      Nuevo Cliente de{" "}
                      <a
                        href={client.propertyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={styles.propertyLink}
                      >
                        {client.propertyInterested}
                      </a>
                    </Typography>
                    <Typography variant="body2" sx={styles.name} gutterBottom>
                      <Typography variant="body2" component="span" sx={styles.contactInfo}>
                        Nombre:
                      </Typography>{" "}
                      {client.name}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <Typography variant="body2" component="span" sx={styles.contactInfo}>
                        Teléfono:
                      </Typography>{" "}
                      {client.phone}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <Typography variant="body2" component="span" sx={styles.contactInfo}>
                        Correo electrónico:
                      </Typography>{" "}
                      {client.email}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <Typography variant="body2" component="span" sx={styles.contactInfo}>
                        Tipo de cliente:
                      </Typography>{" "}
                      {client.clientType}
                    </Typography>
                    <Typography variant="body2" sx={styles.message}>
                      {client.message}
                    </Typography>
                  </>
                }
              />
              <IconButton edge="end" aria-label="delete" sx={styles.closeButton}>
                <CloseIcon />
              </IconButton>
            </ListItem>
            {index !== displayedClients.length - 1 && <Divider variant="inset" component="li" sx={styles.divider} />}
          </React.Fragment>
        ))}
      </List>
      {activities.length > 0 && (
        <Pagination
          count={numberOfPages}
          page={page}
          onChange={handlePageChange}
          color="standard"
          sx={styles.pagination}
        />
      )}
    </>
  );
};

export default ActivitiesList;
