export const useStyles = (theme) => ({
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingBottom: "40px",
    borderRadius: "8px",
    maxWidth: "396px",
    maxHeight: "550px",
    minWidth: "396px",
    minHeight: "480px",
    background: theme.palette.white.main,
  },
  logo: {
    m: 1,
  },
  form: {
    mt: 1,
  },
  loginBtn: {
    mt: 3,
    mb: 2,
  },
  forgotPasswordLink: {
    textDecoration: "underline",
    fontWeight: "400",
    fontSize: "0.875rem",
    lineHeight: 1.43,
    letterSpacing: "0.01071em",
    color: theme.palette.gold.main,
    textDecorationColor: theme.palette.gold.main,
  },
  rememberMeLabel: {
    color: theme.palette.gray.main,
  },
  circularProgress: {
    color: theme.palette.white.main,
  },
  errorMessage: {
    marginTop: "8px",
    marginBottom: "-8px",
  },
});
