export const useStyles = (theme) => ({
  activitiesContainer: {
    backgroundColor: theme.palette.white.main,
    padding: 1,
    textAlign: "center",
    color: theme.palette.black.main,
    minHeight: "300px",
    boxShadow: theme.customShadows.main,
  },
  stadisticsContainer: {
    backgroundColor: theme.palette.white.main,
    padding: 1,
    textAlign: "center",
    color: theme.palette.black.main,
    boxShadow: theme.customShadows.main,
  },
});
