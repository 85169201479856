import React from "react";
import { useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useStyles } from "components/screen-container/components/drawer/styles";

const MenuGroup = ({ children, title, isLastGroup }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <>
      <List sx={styles.menuList}>
        <Typography component="p" sx={styles.menuGroupTitle}>
          {title}
        </Typography>
        {children}
      </List>
      {!isLastGroup && <Divider sx={styles.menuDivider} />}
    </>
  );
};

export default MenuGroup;
