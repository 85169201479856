export const useStyles = (theme) => ({
  main: {
    width: "100%",
    minHeight: "200px",
  },
  title: { mt: 2 },
  avatar: {
    backgroundColor: theme.palette.black.main,
    color: theme.palette.white.main,
    fontWeight: "500",
    fontSize: "1.15rem",
  },
  date: { fontStyle: "italic", color: theme.palette.gray.main, fontWeight: 300 },
  propertyLink: {
    color: theme.palette.gold.main,
    textDecoration: "none",
    fontWeight: "bold",
  },
  name: { mt: 2 },
  contactInfo: { fontWeight: "bold", color: theme.palette.gold.main },
  closeButton: { position: "absolute", right: 15, top: 4 },
  divider: { mb: 2, mr: 2 },
  pagination: {
    marginTop: 2,
    marginBottom: 4,
    display: "flex",
    justifyContent: "center",
    "& .MuiPaginationItem-root": {
      borderRadius: "5px",
      color: theme.palette.gold.main,
      height: "31px",
      fontWeight: "500",
      "&:hover": {
        backgroundColor: theme.palette.lightGray.main,
        color: theme.palette.black.main,
      },
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: theme.palette.black.main,
      color: theme.palette.white.main,
      "&:hover": {
        backgroundColor: theme.palette.black.main,
        color: theme.palette.white.main,
      },
    },
    "& .MuiPaginationItem-page": {
      fontSize: "0.9rem",
    },
  },
  message: { mt: 2, mb: 2 },
});
