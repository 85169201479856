import React from "react";
import { useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import { useStyles } from "screens/general-statistics/components/stadistics-leads/styles";

ChartJS.register(BarElement, Tooltip, Legend, LinearScale, CategoryScale, LineController, LineElement, PointElement);

const StadisticsLeads = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const { stadistics } = useSelector((state) => state.activity);

  if (!stadistics) {
    return (
      <Box sx={styles.chartContainer}>
        <Typography variant="h6" gutterBottom sx={styles.title}>
          Estadísticas de Prospectos
        </Typography>
      </Box>
    );
  }

  const { leads_count } = stadistics;

  if (
    !leads_count ||
    !leads_count.lastday ||
    !leads_count.lastweek ||
    !leads_count.lastmonth ||
    !leads_count.lasttwo ||
    !leads_count.last2week ||
    !leads_count.last2month
  ) {
    return (
      <Box sx={styles.chartContainer}>
        <Typography variant="h6" gutterBottom sx={styles.title}>
          Estadísticas de Prospectos
        </Typography>
      </Box>
    );
  }

  const calculatePercentage = (current, previous) => Math.round(((current - previous) / previous) * 100);

  const data = {
    labels: ["Últimas 24 Horas", "Últimos 7 Días", "Últimos 30 Días"],
    datasets: [
      {
        label: "Prospectos",
        data: [leads_count.lastday, leads_count.lastweek, leads_count.lastmonth],
        backgroundColor: theme.palette.gold.main,
        yAxisID: "y1",
        order: 1,
      },
      {
        label: "Diferencia",
        type: "line",
        data: [
          calculatePercentage(leads_count.lastday, leads_count.lasttwo),
          calculatePercentage(leads_count.lastweek, leads_count.last2week),
          calculatePercentage(leads_count.lastmonth, leads_count.last2month),
        ],
        backgroundColor: theme.palette.gray.main,
        borderColor: theme.palette.gray.main,
        fill: false,
        yAxisID: "y2",
        order: 0,
      },
    ],
  };

  const options = {
    scales: {
      y1: {
        type: "linear",
        position: "left",
        beginAtZero: true,
        grid: {
          color: theme.palette.lightGray.main,
        },
        ticks: {
          color: theme.palette.black.main,
        },
      },
      y2: {
        type: "linear",
        position: "right",
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          color: theme.palette.black.main,
          callback: function (value) {
            return value + "%";
          },
        },
      },
      x: {
        grid: {
          color: theme.palette.lightGray.main,
        },
        ticks: {
          color: theme.palette.black.main,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";

            if (label === "Diferencia") {
              return label + ": " + context.parsed.y + "%";
            } else {
              return label + ": " + context.parsed.y;
            }
          },
        },
      },
    },
  };

  return (
    <Box sx={styles.chartContainer}>
      <Typography variant="h6" gutterBottom sx={styles.title}>
        Estadísticas de Prospectos
      </Typography>
      <Bar data={data} options={options} />
    </Box>
  );
};

export default StadisticsLeads;
