import React from "react";
import { useSelector } from "react-redux";

const useInitialMenuOption = (currentPath) => {
  const { menu } = useSelector((state) => state.user);

  return menu.reduce((prev, group) => {
    const result = group.children.find((option) => {
      if (Array.isArray(option.children)) {
        return option.children.some((child) => child.route === currentPath);
      }

      return option.route === currentPath;
    });

    if (!result) {
      return prev;
    }

    return result;
  }, null);
};

export default useInitialMenuOption;
