import React from "react";
import { useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "screens/general-statistics/components/stadistics-deals/styles";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import { BarElement, CategoryScale, LinearScale, Title, LineController, LineElement, PointElement } from "chart.js";

ChartJS.register(LinearScale, CategoryScale, Title, BarElement, LineController, LineElement, PointElement);

const StadisticsDeals = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const { stadistics } = useSelector((state) => state.activity);

  if (!stadistics) {
    return (
      <Box sx={styles.chartContainer}>
        <Typography variant="h6" gutterBottom sx={styles.title}>
          Estadísticas de Tratos
        </Typography>
      </Box>
    );
  }

  const { deals_count } = stadistics;

  if (
    !deals_count ||
    !deals_count.active ||
    !deals_count.won ||
    !deals_count.lost ||
    !deals_count.negotiation ||
    !deals_count.waiting
  ) {
    return (
      <Box sx={styles.chartContainer}>
        <Typography variant="h6" gutterBottom sx={styles.title}>
          Estadísticas de Tratos
        </Typography>
      </Box>
    );
  }

  const data = {
    labels: ["Activos", "Ganados", "Perdidos", "En Negociación", "En Espera"],
    datasets: [
      {
        label: "Estadísticas de Tratos",
        data: [deals_count.active, deals_count.won, deals_count.lost, deals_count.negotiation, deals_count.waiting],
        backgroundColor: [
          theme.palette.gold.main,
          theme.palette.primary.main,
          theme.palette.gray.main,
          theme.palette.darkGray.main,
          theme.palette.lightGray.main,
        ],
      },
    ],
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <Box sx={styles.chartContainer}>
      <Typography variant="h6" gutterBottom sx={styles.title}>
        Estadísticas de Tratos
      </Typography>
      <Bar data={data} options={options} />
    </Box>
  );
};

export default StadisticsDeals;
