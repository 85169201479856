import React from "react";
import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { useStyles } from "components/icon-rounded-button/styles";

const IconRoundedButton = ({ children, onClick, style }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Avatar variant="rounded" onClick={onClick} sx={{ ...styles.button, ...style }}>
      {children}
    </Avatar>
  );
};

export default IconRoundedButton;
