import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComponentWithAuth from "components/component-with-auth";
import Login from "screens/login";
import GeneralStatistics from "screens/general-statistics";
import Favorites from "screens/favorites";
import Leads from "screens/leads";
import NotFound from "screens/not-found";
import "App.css";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/dashboard/general" exact element={<ComponentWithAuth element={GeneralStatistics} />} />
          <Route path="/dashboard/favorites" exact element={<ComponentWithAuth element={Favorites} />} />
          <Route path="/dashboard/inbox" exact element={<ComponentWithAuth element={Favorites} />} />

          <Route path="/crm/leads" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/crm/tasks" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/crm/properties" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/crm/contracts/sale" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/crm/contracts/lease" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/crm/contracts/booking" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/crm/contracts/commision" exact element={<ComponentWithAuth element={Leads} />} />

          <Route path="/statistics/clientList" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/statistics/potentialClients" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/statistics/assignedClients" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/statistics/closedClients" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/statistics/clientSources" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/statistics/generalVisits" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/statistics/propertyVisits" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/statistics/requestSources" exact element={<ComponentWithAuth element={Leads} />} />

          <Route path="/security/roles" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/security/accesses" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/security/agents" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/security/agencies" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/security/owners" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/settings/paymentTypes" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/settings/currencies" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/settings/bank" exact element={<ComponentWithAuth element={Leads} />} />
          <Route path="/settings/myProfile" exact element={<ComponentWithAuth element={Leads} />} />

          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
