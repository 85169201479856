export const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: theme.palette.white.main,
    borderRadius: "8px",
    paddingTop: "40px",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingBottom: "40px",
    width: "950px",
  },
  h1: {
    fontSize: "10rem",
    fontWeight: "11",
    height: "200px",
    textAlign: "center",
    color: theme.palette.gold.main,
  },
  h2: {
    fontSize: "3rem",
    fontWeight: "400",
    textAlign: "center",
    color: theme.palette.gray.main,
  },
  h3: {
    fontSize: "2rem",
    fontWeight: "200",
    textAlign: "center",
    color: theme.palette.gold.main,
  },
  backLink: {
    textDecoration: "none",
    alignSelf: "center",
  },
  backBtn: {
    mt: 5,
    mb: 5,
  },
});
