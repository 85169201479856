export const useStyles = (theme) => ({
  button: {
    cursor: "pointer",
    borderRadius: "8px",
    width: "34px",
    height: "34px",
    fontSize: "1.2rem",
    color: theme.palette.white.main,
    background: theme.palette.black.main,
    transition: "all 0.3s ease-in-out 0s",
    "&:hover": {
      background: theme.palette.gold.main,
      transition: "all 0.3s ease-in-out 0s",
    },
  },
});
