import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import Header from "components/screen-container/components/header";
import Drawer from "components/screen-container/components/drawer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useStyles } from "components/screen-container/styles";
import Container from "components/screen-container/components/container";

const Dashboard = ({ children, title }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const [isDrawerOpen, setDrawerOpen] = React.useState(true);

  const toggleDrawer = () => {
    setDrawerOpen(isDrawerOpen === true ? false : true);
  };

  return (
    <Container isDrawerOpen={isDrawerOpen}>
      <CssBaseline />
      <Header isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} showDrawerButton={true} />
      <Drawer isDrawerOpen={isDrawerOpen} />
      <Box
        component="main"
        style={{
          ...styles.content,
          ...(isDrawerOpen && styles.contentShift),
        }}
      >
        {title && (
          <Box sx={styles.titleContainer}>
            <Typography sx={styles.title}>{title}</Typography>
          </Box>
        )}

        {children}
      </Box>
    </Container>
  );
};

export default Dashboard;
