import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "components/loading-indicator/style";

const LoadingIndicator = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box sx={styles.container}>
      <CircularProgress size={70} sx={styles.loadingIndicator} />
    </Box>
  );
};

export default LoadingIndicator;
