import { createTheme } from "@mui/material/styles";

export const getTheme = () =>
  createTheme({
    palette: {
      primary: {
        main: "#353535",
        dark: "#222222",
      },
      white: {
        main: "#ffffff",
      },
      whiteOpacity: {
        main: "rgba(255, 255, 255, 0.75)",
      },
      lightGray: {
        main: "#f0f0f0",
      },
      gray: {
        main: "#616161",
      },
      darkGray: {
        main: "#353535",
      },
      black: {
        main: "#222222",
      },
      gold: {
        main: "#CC9F53",
      },
    },
    customShadows: {
      main: "0px 1px 2px -1px rgba(0, 0, 0, 0.08), 0px 1px 2px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px 0px rgba(0, 0, 0, 0.08)",
    },
  });
