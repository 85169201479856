export const useStyles = (theme) => ({
  container: {
    background: theme.palette.whiteOpacity.main,
    display: "flex",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999999,
    borderRadius: 8,
  },
  loadingIndicator: {
    color: theme.palette.gold.main,
  },
});
