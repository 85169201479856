import React from "react";
import BackgroundContainer from "components/background-container";
import Form from "screens/login/components/form";
import CssBaseline from "@mui/material/CssBaseline";

const Login = () => {
  return (
    <>
      <CssBaseline />
      <BackgroundContainer>
        <Form />
      </BackgroundContainer>
    </>
  );
};

export default Login;
