import { DRAWER_WIDTH, HEADER_HEIGHT } from "styles/constants";

export const useStyles = (theme) => ({
  main: {
    display: "flex",
  },
  backgroundContainer: {
    width: "100%",
    height: "173px",
    position: "fixed",
    background: theme.palette.darkGray.main,
    marginTop: HEADER_HEIGHT,
  },
  firstBackgroundBar: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: "100%",
    height: "173px",
    position: "fixed",
    background: theme.palette.black.main,
    marginTop: HEADER_HEIGHT,
    marginLeft: DRAWER_WIDTH,
    borderRadius: "8px 0px 0px 0px",
  },
  firstBackgroundBarShift: {
    transition: theme.transitions.create(["margin", "border-radius"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "0px",
    borderRadius: "0px",
  },
  secondBackgroundBar: {
    width: "100%",
    height: "calc(100% - 237px)",
    position: "fixed",
    background: theme.palette.lightGray.main,
    top: "237px",
  },
});
