import { createSlice } from "@reduxjs/toolkit";
import { getActivities, getStadistics } from "redux/activity/thunks";

const initialState = {
  activities: [],
  stadistics: {
    leads_count: null,
    deals_count: null,
  },
  requestingActivities: false,
  requestingStadistics: false,
  error: null,
};

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {},
  extraReducers: {
    [getActivities.pending]: (state) => {
      state.requestingActivities = true;
      state.error = null;
    },
    [getActivities.fulfilled]: (state, { payload }) => {
      state.requestingActivities = false;
      if (payload.success) {
        state.activities = payload.data;
      } else {
        state.error = payload.error || "getActivities Error";
      }
    },
    [getActivities.rejected]: (state, action) => {
      state.requestingActivities = false;
      state.error = action.error;
    },
    [getStadistics.pending]: (state) => {
      state.requestingStadistics = true;
      state.error = null;
    },
    [getStadistics.fulfilled]: (state, { payload }) => {
      state.requestingStadistics = false;
      if (payload.success) {
        state.stadistics = payload.data;
      } else {
        state.error = payload.error || "getStadistics Error";
      }
    },
    [getStadistics.rejected]: (state, action) => {
      state.requestingStadistics = false;
      state.error = action.error;
    },
  },
});

// export actions
export { getActivities, getStadistics };

// export reducer
export default activitySlice.reducer;
