import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "components/screen-container/components/container/styles";

const Container = ({ isDrawerOpen, children }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box sx={styles.main}>
      <Box sx={styles.backgroundContainer} />
      <Box
        sx={{
          ...styles.firstBackgroundBar,
          ...(!isDrawerOpen && styles.firstBackgroundBarShift),
        }}
      />
      <Box sx={styles.secondBackgroundBar} />
      {children}
    </Box>
  );
};

export default Container;
