import { DRAWER_WIDTH, HEADER_HEIGHT } from "styles/constants";

export const useStyles = (theme) => ({
  main: {
    display: "flex",
  },
  content: {
    width: "100%",
    minHeight: `calc(100vh - ${HEADER_HEIGHT} - 50px)`,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.white.main,
    marginLeft: `calc(-${DRAWER_WIDTH} + 25px)`,
    marginRight: 25,
    marginTop: 89,
    zIndex: 2,
    borderRadius: 8,
    position: 'relative',
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 25,
  },
  titleContainer: {
    marginBottom: "32px",
    marginTop: "2px",
  },
  title: {
    color: theme.palette.black.main,
    fontSize: "2rem",
    fontWeight: "500",
  },
});
