import { configureStore, combineReducers } from "@reduxjs/toolkit";
import appReducer from "redux/app/slice";
import userReducer from "redux/user/slice";
import activityReducer from "redux/activity/slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const reducer = combineReducers({
  app: appReducer,
  user: userReducer,
  activity: activityReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
