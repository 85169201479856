import React from "react";
import { Link } from "react-router-dom";
import BackgroundContainer from "components/background-container";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useStyles } from "screens/not-found/styles";

const NotFound = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <BackgroundContainer>
      <Box sx={styles.container}>
        <Typography sx={styles.h1} component="h1">
          404
        </Typography>
        <Typography sx={styles.h2} component="h2">
          Oooops!!
        </Typography>
        <Typography sx={styles.h3} component="h3">
          ESTA PÁGINA NO EXISTE O NO ESTÁ DISPONIBLE
        </Typography>
        <Link to="/dashboard/general" style={styles.backLink}>
          <Button sx={styles.backBtn} variant="contained">
            Volver al Inicio
          </Button>
        </Link>
      </Box>
    </BackgroundContainer>
  );
};

export default NotFound;
