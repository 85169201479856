export const useStyles = (theme) => ({
  avatar: {
    backgroundColor: theme.palette.black.main,
    color: theme.palette.white.main,
    fontWeight: "500",
    fontSize: "1.05rem",
  },
  name: {
    color: theme.palette.gold.main,
    textDecoration: 'none',
    fontSize: '0.95rem',
    fontWeight: 500,
  },
  iconButton: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  }
});
