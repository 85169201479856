import { DRAWER_WIDTH, HEADER_HEIGHT } from "styles/constants";

export const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH})`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: DRAWER_WIDTH,
      height: `calc(100% - ${HEADER_HEIGHT})`,
      top: HEADER_HEIGHT,
      overflow: "hidden",
    },
    "& .MuiDrawer-paperAnchorLeft": {
      border: 0,
    },
  },
  searchBarFirstContainer: {
    background: theme.palette.darkGray.main,
  },
  searchBarSecondContainer: {
    background: theme.palette.black.main,
    height: "70.5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    margin: "16px",
    marginTop: "0px",
  },
  menuGroupContainer: {
    paddingLeft: "16px",
    paddingRight: "16px",
    overflow: "scroll",
  },
  menuList: {
    paddingTop: "0px",
  },
  menuGroupTitle: {
    color: theme.palette.black.main,
    margin: "10px 0px 0.35em",
    padding: "6px",
    fontSize: "0.875rem",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  menuItemContainer: {
    paddingBottom: "10px",
    paddingTop: "10px",
    marginBottom: "4px",
    "&:hover, &.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.lightGray.main,
      borderRadius: "8px",
    },
  },
  menuItemIconContainer: {
    minWidth: "36px",
  },
  menuItemIcon: {
    width: "20px",
    height: "20px",
  },
  menuItemText: {
    color: theme.palette.gray.main,
    margin: "0px",
    fontWeight: "400",
    lineHeight: "1.334em",
    fontSize: "0.875rem",
  },
  selectedMenuItemText: {
    fontWeight: "600",
  },
  menuDivider: {
    borderColor: theme.palette.lightGray.main,
    opacity: 1,
  },
  arrowIcon: {
    width: "20px",
    height: "20px",
  },
  menuItemDetailContainer: {
    paddingBottom: "10px",
    paddingTop: "10px",
    paddingLeft: "41px",
    marginBottom: "4px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: theme.palette.white.main,
      "& p": {
        fontWeight: "600",
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.gray.main,
      },
    },
  },
  selectedItemDetail: {
    "& p": {
      fontWeight: "600",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.gray.main,
    },
  },
  menuItemDetailIconContainer: {
    minWidth: "18px",
  },
  menuItemDetailIcon: {
    width: "7px",
    height: "7px",
  },
  searchBarContainer: {
    background: theme.palette.darkGray.main,
    height: "32px",
    width: "192px",
    borderRadius: "8px",
    p: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  searchTextInput: {
    color: theme.palette.white.main,
    ml: 1,
    flex: 1,
  },
  searchIcon: {
    color: theme.palette.white.main,
    p: "10px",
  },
});
