import { createSlice } from "@reduxjs/toolkit";
import { signIn } from "redux/user/thunks";

const initialState = {
  token: null,
  user: null,
  menu: [],
  requesting: false,
  error: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signOut: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.requesting = true;
      state.error = null;
    },
    [signIn.fulfilled]: (state, { payload }) => {
      state.requesting = false;
      if (payload.success) {
        state.token = payload.data.token;
        state.user = payload.data.user;
        state.menu = payload.data.menu;
        state.error = null;
      } else {
        state.error = "Invalid credentials";
      }
    },
    [signIn.rejected]: (state, action) => {
      state.requesting = false;
      state.error = action.error.message;
    },
  },
});

// actions
const { signOut } = userSlice.actions;

// export actions
export { signOut, signIn };

// export reducer
export default userSlice.reducer;
